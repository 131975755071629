'use strict';

angular.module('windmanagerApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('clients', {
        url: '/clients',
        template: '<clients></clients>',
        authenticate: 'superadmin',
        data: {
          bodyClass: ''
        }
      });
  });
